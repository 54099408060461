<template>
  <!-- Main content -->
  <section class="content">
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="订单号：">
          <el-input
            v-model="parent_order_sn"
            placeholder="请输入订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="user_id：">
          <el-input v-model="uid" placeholder="请输入user_id"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
          <el-button
            class="ElmUpLoadcontanier"
            type="primary"
            :loading="uploading"
          >
            <i class="el-icon-upload el-icon--left"></i>上传
            <ElmUpLoad
              class="ElmUpLoad"
              @upload_success="upload_success"
              @upload_err="upload_err"
              accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </el-button>
          <el-select v-model="uploadType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="orderList" border>
      <el-table-column fixed prop="id" label="编号" width="80px">
      </el-table-column>
      <el-table-column label="user_id（user_key）" width="180">
        <template slot-scope="scope">
          {{ scope.row.user_id }}({{ scope.row.user_key }})
        </template>
      </el-table-column>
      <el-table-column prop="order_sn" label="订单号" width="180px">
      </el-table-column>

      <el-table-column
        prop="item_title"
        label="商品标题"
        width="200"
      ></el-table-column>
      <!-- <el-table-column prop="item_id" label="商品ID" width="120"></el-table-column> -->

      <el-table-column
        prop="platform_commission"
        label="淘宝佣金"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="user_commission"
        label="用户佣金"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="pay_amount"
        label="付款金额"
        width="100"
      ></el-table-column>

      <el-table-column label="订单状态">
        <template slot-scope="scope">
          <span
            :class="
              scope.row.status == 1
                ? 'green'
                : scope.row.status == 3
                ? 'blue'
                : scope.row.status == 4
                ? 'warn'
                : scope.row.status == 5 ||
                  scope.row.status == 6 ||
                  scope.row.status == 7
                ? 'red'
                : ''
            "
          >
            {{ scope.row.status | status }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="violation_type" label="违规类型">
      </el-table-column>
      <el-table-column label="下单时间" width="150">
        <template slot-scope="scope">
          {{ scope.row.order_time | dateTime }}
        </template>
      </el-table-column>
      <el-table-column label="违规处理时间" width="150">
        <template slot-scope="scope">
          {{ scope.row.create_time | dateTime }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, sizes,prev, pager, next,jumper"
      :total="total"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>
  </section>
  <!-- /.content -->
</template>

<script>
import ElmUpLoad from "@/components/elmUpLoad/index.vue";
let common = JSON.parse(window.localStorage.getItem("common"));
import * as api from "@/config/api";
export default {
  name: "illegalOrder",
  components: {
    ElmUpLoad,
  },
  data() {
    return {
      uploadType: 1,
      options: [
        {
          value: 1,
          label: "店铺淘宝客违规订单",
        },
        {
          value: 2,
          label: "虚假交易违规订单",
        },
      ],
      orderList: [],
      total: 0,
      page: 1,
      pagesize: 10,
      payStatusList: common.order_status_list,
      uid: "",
      parent_order_sn: "",
      // order_sn: '',
      // item_id: '',
      // status: '',
      // order_pay_time_start: '',
      // order_pay_time_end: '',

      dialog: false,

      loading: true,
      uploading: false,
      // order_id: '',
      // unionid: '',
      // user_commission: ''
    };
  },
  watch: {},
  filters: {
    status(val) {
      let name = "";
      if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
        common.order_status_list.map((a, index) => {
          if (val === a.type) {
            name = a.name;
          }
        });
      }
      return name;
    },
    dateTime(val) {
      let day = new Date(Number(val) * 1000);
      if (Number(val) === 0) {
        return "";
      }
      return (
        day.getFullYear() +
        "-" +
        `${
          day.getMonth() + 1 < 10
            ? "0" + (day.getMonth() + 1)
            : day.getMonth() + 1
        }` +
        "-" +
        `${day.getDate() < 10 ? "0" + day.getDate() : day.getDate()}` +
        " " +
        `${day.getHours() < 10 ? "0" + day.getHours() : day.getHours()}` +
        ":" +
        `${day.getMinutes() < 10 ? "0" + day.getMinutes() : day.getMinutes()}` +
        ":" +
        `${day.getSeconds() < 10 ? "0" + day.getSeconds() : day.getSeconds()}`
      );
    },
  },
  mounted() {
    this.getIllegalOrder();
  },
  methods: {
    upload_success({ url }) {
      this.uploading = true;
      api.violationAddExcelData(
        { file_path: url, type: this.uploadType },
        (res) => {
          this.$message.success("上传成功");
          this.uploading = false;
        },
        () => {
          this.$message.error("上传失败");
          this.uploading = false;
        }
      );
    },
    upload_err() {
      this.$message.error("上传失败");
      this.uploading = false;
    },
    close() {
      this.show = false;
      this.getIllegalOrder();
    },
    getIllegalOrder(page, size) {
      this.loading = true;
      api.getIllegalOrder(
        {
          user_id: this.uid,
          order_sn: this.parent_order_sn,
          page: page ? page : this.page,
          pagesize: size ? size : this.pagesize,
        },
        (res) => {
          this.orderList = res.data.list;
          this.total = Number(res.data.count);
          this.loading = false;
        }
      );
    },
    sizeChange(val) {
      this.pagesize = val;
      this.getIllegalOrder();
    },
    currentChange(val) {
      this.page = val;
      this.getIllegalOrder();
    },
    seach(page, size) {
      this.getIllegalOrder(page, size);
    },
  },
};
</script>
<style scoped>
.el-table .cell {
  text-align: center;
}

.btn-box {
  text-align: left;
  margin-bottom: 20px;
}

img {
  width: 150px;
}

.red {
  color: #f56c6c;
}

.blue {
  color: #409eff;
}

.green {
  color: #67c23a;
}

.warn {
  color: #e6a23c;
}
.ElmUpLoadcontanier {
  position: relative;
  overflow: hidden;
}
.ElmUpLoad {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
</style>